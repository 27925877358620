<script>
import DealService from "@/services/deal-service";
import CoinInfoFetcherService from "@/services/CoinInfoFetcherService";
import Coin from "@/state/entities/coin";

export default {
  data() {
    return {
      dataLoaded: false,
      result: [],
      purchaseStatisticsChart: {
        series: [],
        chartOptions: {
          labels: [],
          chart: {
            type: "donut",
            height: 210,
          },
          plotOptions: {
            pie: {
              size: 100,
              offsetX: 0,
              offsetY: 0,
              donut: {
                size: "70%",
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontSize: "18px",
                    offsetY: -5,
                  },
                  value: {
                    show: true,
                    fontSize: "20px",
                    color: "#343a40",
                    fontWeight: 500,
                    offsetY: 5,
                    formatter: function (val) {
                      return "$" + val;
                    },
                  },
                  total: {
                    show: true,
                    fontSize: "13px",
                    label: this.$t('t-total-value'),
                    color: "#9599ad",
                    fontWeight: 500,
                    formatter: function (w) {
                      return (
                          "$" +
                          parseFloat(w.globals.seriesTotals.reduce(function (a, b) {
                            return a + b;
                          }, 0)).toFixed(2)
                      );
                    },
                  },
                },
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return "$" + value;
              },
            },
          },
          stroke: {
            lineCap: "round",
            width: 2,
          },
          colors: ['#FF0000', '#00AA55', '#FF00FF','#F47A1F', '#FDBB2F', '#377B2B', '#7AC142', '#007CC3', '#00529B']
        },
      },
    };
  },
  async mounted() {
    let result = ((await DealService.portfolioAssets()).data).data;
    let portfolioItems = [];
    for (const row of result) {
      //filter for market type is not working on backend side
      if (row.market_type == 'futures') {
        continue;
      }
      portfolioItems.push(row);
      let storageCoin = localStorage.getItem(row.quote_asset.toUpperCase());
      if (!storageCoin) {
        let coinInfoResponse = await CoinInfoFetcherService.coinInfo(row.quote_asset.toUpperCase());
        storageCoin = JSON.stringify(coinInfoResponse.data);
        localStorage.setItem(coinInfoResponse.data.code, storageCoin);
      }
      let coinInfo = JSON.parse(storageCoin);
      const coin = new Coin();
      coin.title = coinInfo.title;
      coin.code = coinInfo.code;
      coin.image_path = coinInfo.image_path;
      row.coin = coin;
    }

    this.result = portfolioItems;
    // console.log(portfolioItems)
    this.dataLoaded = true;

    let dealsValues = [];
    let assetsNames = [];

    this.result.forEach(function(deal){
        let dealBaseVolume = parseFloat(parseFloat(deal.base_volume).toFixed(2));
        dealsValues.push(dealBaseVolume);
        assetsNames.push(deal.quote_asset);
    });

    this.purchaseStatisticsChart.series = dealsValues;
    this.purchaseStatisticsChart.chartOptions = {
      ...this.purchaseStatisticsChart.chartOptions,
      labels: assetsNames,
    };
  },
}
</script>

<template>
  <div class="card">
    <div class="card-header border-1 align-items-center d-flex bg-soft-info bg-opacity-10">
      <h4 class="card-title mb-0 flex-grow-1">{{ $t("t-purchase-statistics") }}</h4>
    </div>
    <div class="card-body" style="min-height: 250px;">
      <div class="text-center" v-show="dataLoaded === false" style="margin-top:90px;">
        <div class="spinner-border text-danger text-center" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="text-center" v-show="dataLoaded === true && result.length === 0" style="opacity: 0.5;">
        <h1>{{ $t("t-no-assets") }}</h1>
        <span class="ri-donut-chart-fill ri-8x"></span>
      </div>

      <div v-show="!(dataLoaded === true && result.length === 0)">
        <apexchart
            class="apex-charts"
            height="200"
            dir="ltr"
            id="purchase_statistics"
            :series="purchaseStatisticsChart.series"
            :options="purchaseStatisticsChart.chartOptions"
        ></apexchart>
        <ul class="list-group list-group-flush border-dashed mb-0 mt-3 pt-2">
          <li class="list-group-item px-0" v-for="(deal, index) of result" :deal="deal" :key="index">
            <div class="d-flex">
              <div class="flex-shrink-0 avatar-xs">
                <span class="avatar-title bg-light p-1 rounded-circle">
                  <img :src="deal.coin? deal.coin.image_path : ''" class="avatar-xxs" alt=""/>
                </span>
              </div>
              <div class="flex-grow-1 ms-2">
                <h6 class="mb-1">{{deal.coin? deal.coin.title: ''}}</h6>
                <p class="fs-12 mb-0 text-muted">
                  <i class="mdi mdi-circle fs-10 align-middle me-1" :style="'color:'+ this.purchaseStatisticsChart.chartOptions.colors[index]"></i>
                  {{deal.quote_asset}}
                </p>
              </div>
              <div class="flex-shrink-0 text-end">
                <h6 class="mb-1"><span class="text-muted">{{deal.quote_asset}}</span> {{ deal.quote_volume }}</h6>
                <p class="text-default fs-12 mb-0">{{ parseFloat(deal.base_volume).toFixed(2) }} $</p>
              </div>
            </div>
          </li>
        </ul>
      </div>

    </div>
    <div class="card-footer text-end bg-soft-info bg-opacity-10">
      <a href="/portfolio-assets">{{ $t('t-my-portfolio') }}</a>
    </div>
  </div>
</template>
