<script>
import SwiperCore from "swiper";
import "swiper/swiper-bundle.css";

SwiperCore.use([]);

import Layout from "./../layouts/main";
import appConfig from "./../../app.config.json";
import OverallProfit from "@/components/dashboard/OverallProfit";
import TodaysProfit from "@/components/dashboard/TodaysProfit";
import ThisMonthProfit from "@/components/dashboard/ThisMonthProfit";
import DailyProfitChart from "@/components/dashboard/DailyProfitChart.vue";
import MontlyProfitChart from "@/components/dashboard/MontlyProfitChart";
import PurchaseStatistics from "@/components/dashboard/PurchaseStatistics";
import LastSuccessDeals from "@/components/dashboard/LastClosedDeals";
// import Bot from "@/state/entities/bot";

export default {
  components: {
    DailyProfitChart,
    MontlyProfitChart,
    LastSuccessDeals,
    PurchaseStatistics,
    OverallProfit,
    TodaysProfit,
    ThisMonthProfit,
    Layout,
  },
  page: {
    title: "Dashboard",
    meta: [{
      name: "Dashboard panel",
      content: appConfig.description
    }],
  },
  data() {
    return {
      items: [],
    };
  },

  computed: {
    // bots() {
    //   return Bot.all();
    // },
  },
  // async beforeMount() {
  //   (await Bot.api().get('/bot'));
  // },
};
</script>

<template>
  <Layout>

<!--    <div class="row">-->
<!--      <div class="col-xl-4 col-md-6">-->
<!--        &lt;!&ndash; card &ndash;&gt;-->
<!--        <div class="card card-animate">-->
<!--          <div class="card-body">-->
<!--            <div class="d-flex align-items-center">-->
<!--              <div class="flex-grow-1">-->
<!--                <p class="text-uppercase fw-medium text-muted mb-0">Today Earnings</p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="d-flex align-items-end justify-content-between mt-0">-->
<!--              <div>-->
<!--                <h4 class="fs-22 fw-semibold ff-secondary mb-0">-->
<!--                  $-->
<!--                  <span class="counter-value" data-target="559.25">-->
<!--                  559.25</span>k-->
<!--                </h4>-->
<!--              </div>-->
<!--              <div class="avatar-sm flex-shrink-0">-->
<!--                  <span class="avatar-title bg-soft-success rounded fs-1">-->
<!--                      <i class="mdi mdi-calendar-today text-success"></i>-->
<!--                  </span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>&lt;!&ndash; end card body &ndash;&gt;-->
<!--        </div>&lt;!&ndash; end card &ndash;&gt;-->
<!--      </div>&lt;!&ndash; end col &ndash;&gt;-->

<!--      <div class="col-xl-4 col-md-6">-->
<!--        &lt;!&ndash; card &ndash;&gt;-->
<!--        <div class="card card-animate">-->
<!--          <div class="card-body">-->
<!--            <div class="d-flex align-items-center">-->
<!--              <div class="flex-grow-1">-->
<!--                <p class="text-uppercase fw-medium text-muted mb-0">Today Earnings</p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="d-flex align-items-end justify-content-between mt-0">-->
<!--              <div>-->
<!--                <h4 class="fs-22 fw-semibold ff-secondary mb-0">-->
<!--                  $-->
<!--                  <span class="counter-value" data-target="559.25">-->
<!--                  559.25</span>k-->
<!--                </h4>-->
<!--              </div>-->
<!--              <div class="avatar-sm flex-shrink-0">-->
<!--                  <span class="avatar-title bg-soft-success rounded fs-1">-->
<!--                      <i class="mdi mdi-calendar-month text-success"></i>-->
<!--                  </span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>&lt;!&ndash; end card body &ndash;&gt;-->
<!--        </div>&lt;!&ndash; end card &ndash;&gt;-->
<!--      </div>&lt;!&ndash; end col &ndash;&gt;-->





<!--    </div>-->

    <div class="row">
      <div class="col-xl-6">
        <div class="card crm-widget">
          <div class="card-header border-bottom-dashed d-flex align-items-center bg-soft-info bg-opacity-10" style="padding: 0.5rem 1rem;">
            <h4 class="card-title mb-0 flex-grow-1">{{ $t("t-profit") }}</h4>
          </div>
          <div class="card-body" style="padding: 0.5rem 1rem;">
            <div class="row row-cols-md-3 row-cols-3">
              <TodaysProfit></TodaysProfit>
              <ThisMonthProfit></ThisMonthProfit>
              <OverallProfit></OverallProfit>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-sm-12">
          <DailyProfitChart></DailyProfitChart>
      </div>
      <div class="col-lg-6 col-sm-12">
        <MontlyProfitChart></MontlyProfitChart>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4 col-sm-12">
        <PurchaseStatistics></PurchaseStatistics>
      </div>
      <div class="col-lg-4 col-sm-12">
        <LastSuccessDeals></LastSuccessDeals>
      </div>
      <div class="col-lg-4 col-sm-12">
        <!--<PurchaseStatistics></PurchaseStatistics>-->
      </div>
    </div>
  </Layout>
</template>
